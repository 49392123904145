.bookings {
  background-color: #fff8eb;
  min-height: 100vh;

  &_monthnameheader{
    font-size: 36px;
    font-weight: bold;
    color: #ffa600;
    display: flex;
    justify-content: center;

    &_container {
      display: flex;
      justify-content: space-between;
      width: 980px;

      div {
        margin: 0 1rem;
      }
    }
  }

  &_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &_navigatebutton {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  &_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 140px;
  }

  &_columnheader {
    padding: 4px;
    margin: 0 8px;
  }

  &_dayname {
    font-size: 12px;
  }

  &_monthname {
    font-size: 4px;
  }

  &_day {
    font-size: 28px;
    font-weight: bold;
  }

  &_card {
    background-color: white;
    border-radius: 4px;
    border: #ffa600 1px solid;
    height: 32px;
    padding: 8px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &_card:hover {
    box-shadow: 4px 4px 10px 0 #6544021b;
  }

  &_card__disabled {
    opacity: 0.2;
    cursor: default;
  }

  &:hover {
    box-shadow: none;
  }
}
